import SYNC_POP_UP_CONSTANT from '../../../constants/historyReduxConstant/OnSwitchPopUpReduxConstant';

const initialState = {
  popUpSync: 'LINKED_IN',
};
// eslint-disable-next-line import/prefer-default-export
export const switchPopUpData = (state = initialState, action) => {
  switch (action.type) {
    case SYNC_POP_UP_CONSTANT.SYNC_POP_UP_ON_SWITCH:
      return {
        ...state,
        popUpSync: action.data,
      };
    default:
      return state;
  }
};
