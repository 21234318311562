import React, { useEffect, useMemo, useState } from 'react';
import is from 'is_js';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import './App.css';
import PropTypes from 'prop-types';
import Notifications from 'react-notify-toast';
import UAParser from 'ua-parser-js';

import Layout from './components/commonComponents/layout/Layout';
import SignupWithLinkedIn from './components/authentication/login-page/SignupWithLinkedIn';
import FollowUps from './components/dashboard/follow-ups/FollowUps';
import Crm from './components/dashboard/crm/CRM';
import AuthRedirect from './components/dashboard/AuthRedirect';
import OpportunityDetails from './components/dashboard/follow-ups/UpcomingActions/OpportunityDetails/OpportunityDetails';
import Account from './components/commonComponents/upperHeader/Account/Account';

import PopUp from './components/commonComponents/PopUp/PopUp';
import { getClientError } from './redux/actions/clientErrorAction/ClientErrorAction';
import { SOCKET_URL } from './constants/UrlConstant';
import { logoutUser } from './redux/actions/accountAction/AccountAction';
import Strategy from './components/dashboard/strategy/Strategy';
import Dashboard from './components/dashboard/Dashboard/Dashboard';
import { detectExtension } from './helpers/ExtensionDetaction';
// import POPUP_REDUX_CONSTANT from './redux/constants/popUpConstant/PopUpConstant';

const PrivateRoute = ({ component, ...options }) => {
  const isLoggedIn =
    localStorage.getItem('userToken') !== null && localStorage.getItem('userToken').length !== 0;

  const finalComponent = isLoggedIn ? component : SignupWithLinkedIn;

  if (isLoggedIn && !component) {
    return (
      <Route {...options}>
        <Redirect to="/dashboard" />
      </Route>
    );
  }

  return (
    <Layout>
      <Route {...options} component={finalComponent} />
    </Layout>
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func,
};
PrivateRoute.defaultProps = {
  component: null,
};

const uaParser = new UAParser();
const browserName = uaParser.getBrowser().name;

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExtensionNotInstalled, setIsExtensionNotInstalled] = useState(false);

  const isExtensionInstalled = isInstalled => {
    if (!isInstalled) {
      setIsExtensionNotInstalled(true);
    } else {
      setIsExtensionNotInstalled(true);
    }
  };

  useEffect(() => {
    dispatch(getClientError());
    detectExtension('edcpdcdhmbheiolfnlllhgpnamddkdlo', isExtensionInstalled);
  }, []);
  useEffect(() => {
    if (localStorage.getItem('userToken') !== null) {
      const socket = socketIOClient(`${SOCKET_URL}?token=${localStorage.getItem('userToken')}`);
      if (socket) {
        socket.on('FromAPI', data => {
          if (data.type === 'LOGOUT_USER') {
            logoutUser();
            history.push('/login');
          }
        });
      }
    }
  }, []);

  // const onClosePopup = () => {
  //   dispatch({
  //     type: POPUP_REDUX_CONSTANT.POP_UP_MESSAGE,
  //     data: null,
  //   });
  // };
  // const checkCookiee = useSelector(state => state.popUpReducer);

  const getPopup = useMemo(() => {
    if (isExtensionNotInstalled && browserName === 'Chrome' && is.desktop()) {
      return (
        <PopUp
          popupData="extension_not_installed"
          isExtensionNotInstalled={isExtensionNotInstalled}
        />
      );
    }
    // if (
    //   checkCookiee !== 'extension_not_installed' &&
    //   isExtensionNotInstalled === false &&
    //   browserName === 'Chrome' &&
    //   is.desktop()
    // ) {
    //   return <PopUp popupData={checkCookiee} onClosePopup={onClosePopup} />;
    // }
    if (is.not.desktop()) {
      return <PopUp popupData="device_not_desktop" />;
    }
    if (is.mobile()) {
      return <PopUp popupData="device_mobile" />;
    }
    if (browserName !== 'Chrome') {
      return <PopUp popupData="browser_not_supported" />;
    }

    return null;
  }, [isExtensionNotInstalled]);

  return (
    <div className="App">
      <Notifications />
      {getPopup}
      <Router>
        <Route>
          <Switch>
            <Route exact path="/login" component={SignupWithLinkedIn} />
            <Route exact path="/auth-verify" component={AuthRedirect} />
            <PrivateRoute exact path="/" />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/followups" component={FollowUps} />
            <PrivateRoute exact path="/crm" component={Crm} />
            <PrivateRoute exact path="/strategy" component={Strategy} />
            <PrivateRoute
              exact
              path="/followups/opportunitydetails/:id"
              component={OpportunityDetails}
            />
            <PrivateRoute exact path="/account" component={Account} />

            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        </Route>
      </Router>
    </div>
  );
}

export default App;
