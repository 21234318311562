import HISTORY_REDUX_CONSTANT from '../../../constants/historyReduxConstant/HistoryReduxConstant';
// eslint-disable-next-line import/no-named-as-default
import HistoryService from '../../../../services/follow-up-service/historyService/HistoryService';
import POPUP_REDUX_CONSTANT from '../../../constants/popUpConstant/PopUpConstant';

// eslint-disable-next-line import/prefer-default-export
export const fetchConversation = (id, data, params = {}) => {
  return dispatch => {
    dispatch({
      type: HISTORY_REDUX_CONSTANT.LOADING_OPPORTUNITY_CONVERSATION,
    });
    return new Promise((resolve, reject) => {
      HistoryService.fetchConversation(id, data, params)
        .then(response => {
          if (response?.data?.status === 'SUCCESS') {
            if (data) {
              dispatch({
                type: HISTORY_REDUX_CONSTANT.APPEND_OPPORTUNITY_CONVERSATION,
                data: response.data,
              });
            } else {
              dispatch({
                type: HISTORY_REDUX_CONSTANT.FETCH_OPPORTUNITY_CONVERSATION,
                data: response.data,
              });
            }
          }
          resolve();
        })
        .catch(e => {
          if (e.response?.data?.status === 'READ_ERROR_MESSAGE') {
            dispatch({
              type: POPUP_REDUX_CONSTANT.POP_UP_MESSAGE,
              data: e.response.data.message,
            });
            reject();
          } else if (e?.response?.data?.status === undefined) {
            dispatch({
              type: HISTORY_REDUX_CONSTANT.ERROR_OPPORTUNITY_CONVERSATION,
            });
          } else if (e?.response?.data?.status === 'INTERNAL_SERVER_ERROR') {
            dispatch({
              type: HISTORY_REDUX_CONSTANT.ERROR_OPPORTUNITY_CONVERSATION,
            });
          }
        });
    });
  };
};

export const getChatHistoryFromSalesNavOrLinkedIn = data => {
  return dispatch => {
    dispatch({
      type: HISTORY_REDUX_CONSTANT.FETCH_HISTORY_FROM_LINKEDIN_OR_SALES_NAV,
      data,
    });
  };
};

export const clearConversation = () => {
  return dispatch => {
    dispatch({
      type: HISTORY_REDUX_CONSTANT.RESET_OPPORTUNITY_CONVERSATION_DATA,
    });
  };
};

export const onSwitchHistoryFails = data => {
  return dispatch => {
    dispatch({
      type: HISTORY_REDUX_CONSTANT.SWITCH_HISTORY_FAIL,
      data,
    });
  };
};
