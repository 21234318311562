// eslint-disable-next-line import/prefer-default-export
export function detectExtension(extensionId, callback) {
  const img1 = new Image();
  img1.src = `chrome-extension://${extensionId}/img/lf_icon.png`;
  img1.onload = function () {
    callback(true);
  };
  img1.onerror = function (e) {
    console.log(e);
    callback(false);
  };
}
