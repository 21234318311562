import STRATEGY_REDUX_CONSTANT from '../../constants/strategyReduxConstant/StrategyConstatant';

// eslint-disable-next-line import/prefer-default-export
const initialState = {
  strategyData: [],
  isLoading: true,
};

// eslint-disable-next-line import/prefer-default-export
export const strategy = (state = initialState, action) => {
  switch (action.type) {
    case STRATEGY_REDUX_CONSTANT.STRATEGY_DATA:
      return {
        isLoading: false,
        strategyData: action.data,
      };
    case STRATEGY_REDUX_CONSTANT.RESET_STRATEGY_LOADER:
      return {
        ...initialState,
        isLoading: false,
      };
    default:
      return state;
  }
};
