const DASHBOARD_REDUX_CONSTANT = {
  DASHBOARD_DATA: 'DASHBOARD_DATA',
  OPPRTUNITIES_REDUX_CONSTANT: 'OPPRTUNITIES_REDUX_CONSTANT',
  PIPELINE_REDUX_CONSTANT: 'PIPELINE_REDUX_CONSTANT',
  CLIENT_QUOTE: 'CLIENT_QUOTE',
  TOTAL_SALES_REDUX_CONSTANT: 'TOTAL_SALES_REDUX_CONSTANT',
  RESET_PIPELINE_GRAPH_DATA: 'RESET_PIPELINE_GRAPH_DATA',
  RESET_TOTAL_SALES_GRAPH_DATA: 'RESET_TOTAL_SALES_GRAPH_DATA',
  UPDATE_DASHBOARD_DATE_VALUE: 'UPDATE_DASHBOARD_DATE_VALUE',
};
export default DASHBOARD_REDUX_CONSTANT;
