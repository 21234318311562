import DASHBOARD_REDUX_CONSTANT from '../../constants/dashboardReduxConstant/DashboardReduxConstant';
// eslint-disable-next-line import/no-named-as-default
import DashboardService from '../../../services/dashboard-services/DashboardServices';

// eslint-disable-next-line import/prefer-default-export
export const fetchOpportunity = () => {
  return dispatch => {
    DashboardService.getOpportunity().then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.OPPRTUNITIES_REDUX_CONSTANT,
          data: response.data.data,
        });
      }
    });
  };
};

export const getDashboardData = () => {
  return dispatch => {
    DashboardService.getDashboardData().then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.DASHBOARD_DATA,
          data: response.data.data,
        });
      }
    });
  };
};

export const fetchPipeLine = () => {
  return dispatch => {
    DashboardService.getPipeline().then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.PIPELINE_REDUX_CONSTANT,
          data: response.data,
        });
      }
    });
  };
};

export const fetchClientQuote = () => {
  return dispatch => {
    DashboardService.getDailyQuote().then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.CLIENT_QUOTE,
          data: response.data.data,
        });
      }
    });
  };
};

// export const getTotalSalesGraphData = () => {
//   return dispatch => {
//     DashboardService.getTotalSales()
//       .then(response => {
//         if (response.data.status === 'SUCCESS') {
//           dispatch({
//             type: DASHBOARD_REDUX_CONSTANT.TOTAL_SALES_REDUX_CONSTANT,
//             data: response.data,
//           });
//         }
//       })
//       .catch(e => {
//         if (e.response.data.status === undefined) {
//           errorNotification('It seems like server is down, Please try after sometime');
//         } else if (e.response.data.status === 'INTERNAL_SERVER_ERROR') {
//           errorNotification('Internal server error');
//         }
//       });
//   };
// };

export const changeDashboardDateValue = (name, value) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_REDUX_CONSTANT.UPDATE_DASHBOARD_DATE_VALUE,
      name,
      value,
    });
  };
};

export const totalSalesDateFilter = data => {
  return dispatch => {
    DashboardService.getTotalSales(data).then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.TOTAL_SALES_REDUX_CONSTANT,
          data: response.data,
        });
      }
    });
  };
};

// eslint-disable-next-line no-unused-vars
export const resetFilterData = (data = {}) => {
  return dispatch => {
    DashboardService.getTotalSales(data).then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: DASHBOARD_REDUX_CONSTANT.TOTAL_SALES_REDUX_CONSTANT,
          data: response.data,
        });
      }
    });
  };
};

export const resetDashboardGraphData = dispatch => {
  dispatch({
    type: DASHBOARD_REDUX_CONSTANT.RESET_PIPELINE_GRAPH_DATA,
  });
  dispatch({
    type: DASHBOARD_REDUX_CONSTANT.RESET_TOTAL_SALES_GRAPH_DATA,
  });
};
