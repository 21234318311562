import StrategyService from '../../../services/strategy-service/StrategyService';
import STRATEGY_REDUX_CONSTANT from '../../constants/strategyReduxConstant/StrategyConstatant';

// eslint-disable-next-line import/prefer-default-export
export const getStrategies = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      StrategyService.getStrategies()
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            dispatch({
              type: STRATEGY_REDUX_CONSTANT.STRATEGY_DATA,
              data: response.data.data,
            });
            resolve();
          }
        })
        .catch(e => {
          dispatch({
            type: STRATEGY_REDUX_CONSTANT.RESET_STRATEGY_LOADER,
          });
          reject();
          throw Error(e);
        });
    });
  };
};
