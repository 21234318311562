const HISTORY_REDUX_CONSTANT = {
  FETCH_OPPORTUNITY_CONVERSATION: 'FETCH_OPPORTUNITY_CONVERSATION',
  LOADING_OPPORTUNITY_CONVERSATION: 'LOADING_OPPORTUNITY_CONVERSATION',
  ERROR_OPPORTUNITY_CONVERSATION: 'ERROR_OPPORTUNITY_CONVERSATION',
  APPEND_OPPORTUNITY_CONVERSATION: 'APPEND_OPPORTUNITY_CONVERSATION',
  FETCH_HISTORY_FROM_LINKEDIN_OR_SALES_NAV: 'FETCH_HISTORY_FROM_LINKEDIN_OR_SALES_NAV',
  RESET_OPPORTUNITY_CONVERSATION_DATA: 'RESET_OPPORTUNITY_CONVERSATION_DATA',
  SWITCH_HISTORY_FAIL: 'SWITCH_HISTORY_FAIL',
  SYNC_POP_UP_ON_SWITCH: 'SYNC_POP_UP_ON_SWITCH',
};
export default HISTORY_REDUX_CONSTANT;
