/* eslint-disable react/no-danger */
import React, { useCallback, useEffect } from 'react';
import './Strategy.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getStrategies } from '../../../redux/actions/strategyAction/StrategyAction';
import Loader from '../../commonComponents/Loader/Loader';

const Strategy = () => {
  const dispatch = useDispatch();
  const { strategyData, isLoading } = useSelector(({ strategy }) => strategy ?? {});

  useEffect(() => {
    document.title = 'Strategy';
    document.getElementsByClassName('common-area')?.[0]?.scrollTo(0, 0);
  }, []);

  useEffect(async () => {
    await dispatch(getStrategies());
  }, []);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {strategyData && !isLoading ? (
        strategyData.length !== 0 ? (
          <div className="strategy-container" id="s1">
            {strategyData?.map((step, index) => (
              <CardBox step={step} index={index} className="strategy" />
            ))}
          </div>
        ) : (
          <div className="no-data-in-strategy">No Data Available</div>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};
export default Strategy;

function CardBox(props) {
  const { step, ...restProps } = props;

  const getData = useCallback(() => {
    let description = step?.description ?? null;
    if (description) {
      const b = description?.match(/(?={{)(.*?)(?=}})/);
      description = description?.replace(
        /\[\[(.*?)]]/,
        `<span class="wistia_embed wistia_async_jhwasu25hu popover=true popoverContent=link" style="display:inline;position:relative"><a href="#">$1</a></span>`
      );

      const val = b?.[1]?.replaceAll('{', '');
      if (val) {
        const aa = val.split('$$');
        const link = `<a href='${aa[1]}' target="_blank">${aa[0]}</a>`;
        return description.replace(/{{(.*?)}}/, link);
      }
    }
    return description;
  }, [step]);

  return (
    <div {...restProps}>
      <div className="common-title chart-title strategy-title">{step.title}</div>
      <span className="strategy-data">
        <span dangerouslySetInnerHTML={{ __html: getData() }} />
      </span>
      <div className="video-script-style">
        <div
          dangerouslySetInnerHTML={{
            __html: step.videoScript,
          }}
        />
      </div>
    </div>
  );
}

CardBox.propTypes = {
  step: PropTypes.shape({
    title: PropTypes.string,
    videoScript: PropTypes.string,
    description: PropTypes.string,
  }),
};

CardBox.defaultProps = {
  step: {
    title: '',
    description: '',
    videoScript: '',
  },
};
