import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import linkFluencer from '../../../assets/images/linkfluencer.png';
import './login.scss';
import { errorNotification } from '../../../constants/Toast';
import { useQueryParams } from '../../../helpers/GetQueryParamHook';

function SignupWithLinkedIn() {
  const { message } = useQueryParams();
  const history = useHistory();
  useEffect(() => {
    document.title = 'Login';
  }, []);
  useEffect(() => {
    if (message) {
      errorNotification(message);
      history.push('/');
    }
  }, [message]);
  return (
    <div className="login-content-container">
      <img alt="linkfluencer" src={linkFluencer} className="logo" />
      <div className="sign-in-content">
        You&apos;re currently logged out of Jayla,
        <br /> to access your account and sync
        <br /> with LinkedIn, click on the Chrome
        <br /> Extension icon to the top right
        <br /> and click sign in.
      </div>
    </div>
  );
}

export default SignupWithLinkedIn;
