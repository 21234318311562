import ReportingService from '../../../services/reporting-service/ReportingService';
import REPORT_REDUX_CONSTANT from '../../constants/reportReduxConstants/ReportReduxConstant';

export const getActivityBreakdownGraphData = ({ startDate, endDate }) => {
  return dispatch => {
    ReportingService.getActivityBreakdown({ startDate, endDate }).then(response => {
      if (response.data.status === 'SUCCESS') {
        dispatch({
          type: REPORT_REDUX_CONSTANT.GET_ACTIVITY_BREAKDOWN_GRAPH_DATA,
          data: response.data.data,
        });
      }
    });
  };
};

export const getPipelineValuesGraphData = ({ startDate, endDate }) => {
  return dispatch => {
    ReportingService.getPipeline({ startDate, endDate }).then(response => {
      if (response.data.status === 'SUCCESS') {
        dispatch({
          type: REPORT_REDUX_CONSTANT.GET_PIPELINE_VALUES_GRAPH_DATA,
          data: response.data,
        });
      }
    });
  };
};

export const getConversationGraphData = ({ startDate, endDate }) => {
  return dispatch => {
    ReportingService.getConversation({ startDate, endDate }).then(response => {
      if (response.data.status === 'SUCCESS') {
        dispatch({
          type: REPORT_REDUX_CONSTANT.GET_CONVERSATION_GRAPH_DATA,
          data: response.data.data,
        });
      }
    });
  };
};

export const getTotalSalesGraphData = ({ startDate, endDate }) => {
  return dispatch => {
    ReportingService.getTotalSales({ startDate, endDate }).then(response => {
      if (response?.data?.status === 'SUCCESS') {
        dispatch({
          type: REPORT_REDUX_CONSTANT.GET_TOTAL_SALES_GRAPH_DATA,
          data: response.data,
        });
      }
    });
  };
};

export const resetReportingGraphData = dispatch => {
  dispatch({
    type: REPORT_REDUX_CONSTANT.RESET_CONVERSATION_GRAPH_DATA,
  });
  dispatch({
    type: REPORT_REDUX_CONSTANT.RESET_TOTAL_SALES_GRAPH_DATA,
  });
};
