import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './OpportunityDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  clearOpportunity,
  deleteOpportunity,
  getOpportunity,
  getOpportunityWithPrevNext,
  syncWithLinkedIn,
} from '../../../../../redux/actions/followUpAction/FollowUpAction';
import OpportunityData from './opprotunity-data/OpportunityData';
import History from './history/History';
import Notes from './notes/Notes';
import Modal from '../../../../commonComponents/Modal/Modal';
import Loader from '../../../../commonComponents/Loader/Loader';
import { resetFilterData } from '../../../../../redux/actions/filterAction/FilterAction';
import { useQueryParams } from '../../../../../helpers/GetQueryParamHook';
import {
  fetchConversation,
  getChatHistoryFromSalesNavOrLinkedIn,
  onSwitchHistoryFails,
} from '../../../../../redux/actions/followUpAction/historyAction/HistoryAction';
import { onSwitchBtnSyncPopUp } from '../../../../../redux/actions/followUpAction/historyAction/SyncPopUpAction';

function OpportunityDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const onBack = () => {
    history.push('/followups');
  };
  const { id } = useParams();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const opportunity = useSelector(state => state.opportunityDetail);
  const filterData = useSelector(({ filterReducer }) => filterReducer ?? {});
  const followupData = useSelector(state => state.followUps);
  const getChatFor = useSelector(({ opportunityHistory }) => opportunityHistory ?? {});
  const [isSaved, setIsSaved] = useState(opportunity?.isSaved ?? false);
  const dealSizes = useMemo(
    () =>
      followupData && followupData.dealSize && followupData.dealSize[0]
        ? followupData.dealSize[0]
        : null,
    [followupData]
  );
  const [fetchDataFromSalesNav, setFetchDataFromSalesNav] = useState(true);

  useEffect(() => {
    document.title = 'Opportunity Details';
    return () => {
      const dealData = {
        endDealValue: dealSizes?.maxDealValue || 999999999,
        startDealValue: dealSizes?.minDealValue || 0,
      };
      dispatch(resetFilterData(dealData));
    };
  }, []);

  useEffect(() => {
    if (opportunity !== null) {
      setIsSaved(opportunity?.isSaved);
    }
  }, [opportunity?.isSaved]);

  const { from } = useQueryParams();

  useEffect(() => {
    if (from !== undefined && from === 'followUps' && filterData) {
      const dataVal = {
        currentOpportunityId: id,
        startDeal: filterData?.startDeal?.value ?? null,
        endDeal: filterData?.endDeal?.value ?? null,
        startDate: filterData?.startDate?.value ?? null,
        endDate: filterData?.endDate?.value ?? null,
        stages:
          Object.entries(filterData?.stageInitialState)
            .filter(data => data[1].value)
            .map(data => data[0]) ?? [],
        likelyHoods:
          Object.entries(filterData?.potentialInitialState)
            .filter(data => data[1].value)
            .map(data => data[0]) ?? [],
      };
      dispatch(getOpportunityWithPrevNext(dataVal));
    }
  }, [
    id,
    filterData,
    filterData.startDate.value,
    filterData.endDate.value,
    filterData.startDeal.value,
    filterData.endDeal.value,
    filterData.stageInitialState,
    filterData.potentialInitialState,
  ]);

  useEffect(() => {
    if (from === undefined && from !== 'followUps') {
      dispatch(getOpportunity(id));
    }
    return () => {
      dispatch(clearOpportunity);
      dispatch(onSwitchHistoryFails('LINKED_IN'));
      dispatch(onSwitchBtnSyncPopUp('LINKED_IN'));
    };
  }, [id]);

  const goToLinkedInProfile = () => {
    window.open(`${opportunity.linkedInUrl}`, '_blank');
  };

  const onSyncClick = useCallback(async () => {
    await dispatch(syncWithLinkedIn(id));
    dispatch(fetchConversation(id, null, { chatFor: getChatFor?.chatFor }));
    if (from !== undefined && from === 'followUps' && filterData) {
      const dataVal = {
        currentOpportunityId: id,
        startDeal: filterData?.startDeal?.value ?? null,
        endDeal: filterData?.endDeal?.value ?? null,
        startDate: filterData?.startDate?.value ?? null,
        endDate: filterData?.endDate?.value ?? null,
        stages:
          Object.entries(filterData?.stageInitialState)
            .filter(data => data[1].value)
            .map(data => data[0]) ?? [],
        likelyHoods:
          Object.entries(filterData?.potentialInitialState)
            .filter(data => data[1].value)
            .map(data => data[0]) ?? [],
      };
      dispatch(getOpportunityWithPrevNext(dataVal));
    }
  }, [
    id,
    filterData,
    filterData.startDate.value,
    filterData.endDate.value,
    filterData.startDeal.value,
    filterData.endDeal.value,
    filterData.stageInitialState,
    filterData.potentialInitialState,
    setFetchDataFromSalesNav,
    getChatFor?.chatFor,
  ]);
  const deleteSyncClick = () => {
    setIsModelOpen(true);
  };
  const onClosePopup = () => {
    setIsModelOpen(false);
  };
  const onDeleteData = () => {
    setIsModelOpen(false);
    deleteOpportunity(id, history.replace('/followups'));
  };

  const prevOpportunityFetch = () => {
    const prevOpportunityId = opportunity?.prevId ?? null;
    if (prevOpportunityId) {
      history.push({
        pathname: opportunity?.prevId,
        search: 'from=followUps',
      });
    }
  };
  const nextOpportunityFetch = () => {
    const nextOpportunityId = opportunity?.nextId ?? null;
    if (nextOpportunityId) {
      history.push({
        pathname: opportunity?.nextId,
        search: 'from=followUps',
      });
    }
  };
  const onClickSwitchHistory = useCallback(async () => {
    await setFetchDataFromSalesNav(e => !e);
    dispatch(
      getChatHistoryFromSalesNavOrLinkedIn(fetchDataFromSalesNav ? 'SALES_NAVIGATOR' : 'LINKED_IN')
    );
    if (getChatFor?.chatFor === 'LINKED_IN') dispatch(onSwitchBtnSyncPopUp('SALES_NAVIGATOR'));
    else dispatch(onSwitchBtnSyncPopUp('LINKED_IN'));
    try {
      await dispatch(
        fetchConversation(id, null, {
          chatFor: fetchDataFromSalesNav ? 'SALES_NAVIGATOR' : 'LINKED_IN',
        })
      );
    } catch {
      setFetchDataFromSalesNav(e => !e);
      if (fetchDataFromSalesNav) {
        dispatch(onSwitchHistoryFails('LINKED_IN'));
      } else {
        dispatch(onSwitchHistoryFails('SALES_NAVIGATOR'));
      }
    }
  }, [fetchDataFromSalesNav, getChatFor?.chatFor]);

  useEffect(() => {
    if (getChatFor?.chatFor === 'LINKED_IN') {
      setFetchDataFromSalesNav(true);
    } else if (getChatFor?.chatFor === 'SALES_NAVIGATOR') {
      setFetchDataFromSalesNav(false);
    }
  }, [getChatFor?.chatFor]);

  return (
    <>
      {isModelOpen && (
        <Modal
          description="Are you sure you want to delete this opportunity?"
          title="Delete Opportunity"
          deleteData={onDeleteData}
          onClosePopup={onClosePopup}
        />
      )}
      <div className="opportunity-header">
        <div className="breadcrumb common-subtitle">
          <span onClick={onBack}>Sales Opportunity</span>
          <span>&nbsp;{'> Contact Details'}</span>
        </div>
        <div className="buttons-row">
          <button type="submit" className="button success-button btn-size" onClick={onSyncClick}>
            SYNC
          </button>
          {isSaved && (
            <button
              type="button"
              className="button danger-button btn-size"
              onClick={deleteSyncClick}
            >
              DELETE OPPORTUNITY
            </button>
          )}
        </div>
      </div>

      {opportunity && !opportunity?.isVisited && (
        <div className="warning">
          <span>!</span>
          Only add opportunities that are good prospects, we don&apos;t recommend adding someone
          before a conversation has opened up on LinkedIn.
        </div>
      )}

      {opportunity ? (
        <>
          <div className="opportunity-container">
            <div className="opportunity-left">
              <OpportunityData opportunityData={opportunity} goToLinkedIn={goToLinkedInProfile} />
              <Notes />
              {from === 'followUps' && (
                <div className="prev-next-row">
                  <button
                    type="button"
                    className={`${!opportunity?.prevId ? 'button-disable' : 'page-link'}`}
                    style={{fontWeight:500}}
                    onClick={prevOpportunityFetch}
                    disabled={!opportunity?.prevId}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className={`${!opportunity?.nextId ? 'button-disable' : 'page-link'}`}
                    onClick={nextOpportunityFetch}
                    style={{fontWeight:500}}
                    disabled={!opportunity?.nextId}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
            <div>
              <History />
              {opportunity && opportunity?.showSalesButton && (
                <div className="button-to-navigate">
                  <button
                    type="button"
                    className="button primary-button slim-button mt-10"
                    onClick={onClickSwitchHistory}
                  >
                    {fetchDataFromSalesNav ? 'Show Sales Navigator Inbox' : 'Show LinkedIn Inbox'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default OpportunityDetails;
