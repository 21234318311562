import HISTORY_REDUX_CONSTANT from '../../../constants/historyReduxConstant/HistoryReduxConstant';

// eslint-disable-next-line import/prefer-default-export
export const onSwitchBtnSyncPopUp = data => {
  return dispatch => {
    dispatch({
      type: HISTORY_REDUX_CONSTANT.SYNC_POP_UP_ON_SWITCH,
      data,
    });
  };
};
