import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="dot dot-1" />
      <div className="dot dot-2" />
      <div className="dot dot-3" />
      <div className="dot dot-4" />
    </div>
  );
};

export default Loader;
